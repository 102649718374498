<template>
  <div class="about-us-page eats-page">
    <section class="section-food">
      <div class="container">
        <div class="section-food-header">
          <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
              <div class="text-lg">
                {{ $t("Joylashgan hududingizdagi taomlar") }}
              </div>
              <div class="text-sm">
               {{$t('text for subfilter, some text')}}
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="section-food-selects">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select">
                      <div class="custom-select">
                        <select>
                          <option value="">{{ $t("Shahrni tanlang") }}</option>
                          <option value="">{{ $t("Shahrni tanlang") }}</option>
                          <option value="">{{ $t("Shahrni tanlang") }}</option>
                          <option value="">{{ $t("Shahrni tanlang") }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select my-select-last">
                      <div class="custom-select">
                        <select>
                          <option value="">{{ $t("Regionni tanlang") }}</option>
                          <option value="">{{ $t("Regionni tanlang") }}</option>
                          <option value="">{{ $t("Regionni tanlang") }}</option>
                          <option value="">{{ $t("Regionni tanlang") }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="food-menu">
            <ul class="bottom-border">
              <li>
                <a href="#!">{{ $t("Toshkent") }}</a>
              </li>
              <li>
                <a href="#!">{{ $t("Yunsobod") }}</a>
              </li>
              <li>
                <a href="#!">{{ $t("Norin") }}</a>
              </li>
            </ul>
            <div class="row margin-top">
              <div
                class="col-lg-3 col-md-4 col-sm-6 col-11"
                v-for="index in 12"
                :key="index"
              >
                <food-card> </food-card>
              </div>
            </div>
            <div class="more-button">
              <a href="#!">{{ $t("Barchasi") }}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="au-first">
      <div class="container">
        <mini-cards></mini-cards>
      </div>
    </section>
  </div>
</template>

<script>
import FoodCard from "../elements/FoodCard.vue";
import MiniCards from "../elements/MiniCards.vue";
import { mapState } from "vuex";

export default {
  components: { MiniCards, FoodCard },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState(["organizations"]),
  },
};
</script>

<style>
</style>